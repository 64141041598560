import { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Select, DatePicker } from "antd";
import styles from "../NftSold/NFTSold.module.sass";
import axios from "axios";
import { donations } from "../../../environment";
import moment from "moment";
import { causesListing } from '../../../environment';
const { RangePicker } = DatePicker;
function CharitableDonation() {
  const [dateFilter, setDateFilter] = useState("all");
  const [roleFilter, setRoleFilter] = useState(null);
  const [categoryFilter,setCategoryFilter]=useState(null)
  const [orignalData,setOrignalData]=useState(null)
  const [causeFilter,setCauseFilter]=useState(null)
  const [causesOptions,setCausesOptions]=useState(null)
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [data, setData] = useState(null);
  const options = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 24 hours", value: "last24Hours" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },

  ];
  const typeoptions = [
    { label: "All", value: "all" },
    { label: "Category", value: "category" },
    { label: "NFT type", value: "nft" },
    { label: "Organizations", value: "organizations" },
    { label: "Users", value: "users" },
  ];
  const mainCategories = [
    {
      label: "Digital Art",
      value: "digital art",
    },
    {
      label: "Photography",
      value: "photography",
    },
    {
      label: "Text-based",
      value: "text-based",
    },
    {
      label: "Original & Unique Artwork (1/1)",
      value: "original-unique-artwork",
    },
    {
      label: "Event Ticket",
      value: "event-ticket",
    },
    {
      label: "Membership ID",
      value: "membership-id",
    },
    {
      label: "Video clips",
      value: "video-clips",
    },
    {
      label: "Music",
      value: "music",
    },
    {
      label: "AI-Generative",
      value: "ai-generative",
    },
    {
      label: "Avatars",
      value: "avatars",
    },
    {
      label: "Video clips & GIFs",
      value: "video-clips-gifs",
    },
  ];
  const roleOption = [
    { label: "Organization", value: "organization" },
    { label: "User", value: "user" },
  ];
  for (let i = 4; i < 4; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  for (let i = 5; i < 5; i++) {
    typeoptions.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const getStats = async () => {
    const {startDate,endDate}=dateRange
    const stats = await axios.get(donations, {
      params: {
        ...(!startDate && !endDate&& { dateFilter }),
        roleFilter,
        ...causeFilter&&{causeFilter},
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        type:"user"
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    setOrignalData(stats.data.data)
    formatData(stats.data.data)
  };
  useEffect(()=>{
    if(categoryFilter){
     const filteredData= orignalData?.filter((item)=>item?.nft[0]?.secondaryType===categoryFilter)
     formatData(filteredData)
    }else{
      getStats();
    }

  },[categoryFilter])
  const formatData=(data)=>{
    let  dataToFormat=null
    if(categoryFilter){
      dataToFormat=  data.filter((item)=>item?.nft[0]?.secondaryType===categoryFilter)
    }else {
      dataToFormat=data
    }
    let count = 0;
    const res = data.map((item) => {
      const createdAt = moment(item.createdAt);
      count = count + item?.price;
      return {
        name:createdAt.format("D MMMM HH:mm"),
          // dateFilter === "today"
          //   ? createdAt.format("HH:mm")
          //   : createdAt.format("D MMMM"),
        Donation: count,
        uv: count,
      };
    });
    setData(res);
  }
  useEffect(() => {
    getStats();
  }, [dateFilter,roleFilter,causeFilter,dateRange]);
  const getCausesListing = async () => {
    const causesData = await axios.get(causesListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (causesData?.data) {
      formatCauses(causesData?.data?.data)
      setCauses(causesData?.data?.data)
    }
  };
  const formatCauses=(data)=>{
    const formatedCauses= data.map((cause)=>{
       return {
         label:cause.categoryName,
         value:cause._id
       }
     })
     formatedCauses.unshift({label:'All',value:'all'})
     setCausesOptions(formatedCauses);
  }
  const handleChange = (value) => {
    setDateFilter(value)
  };
   useEffect(() => {
    getCausesListing();
  }, []);
  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }
  const handleRangePicker = (dates) => {
    setDateFilter("all");
    setDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };
  
  
  return (
    <div className={styles.analyticscard}>
      <h3>Charitable Donations</h3>
      <div className={styles.selects}>
        <Select
          //   mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Particular date"
          defaultValue={["Today"]}
          onChange={handleChange}
          options={options}
          value={dateFilter}
          disabled={dateRange?.startDate}
        />
        {/* <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Category"
          // defaultValue={["Category"]}
          onChange={(value)=>setCategoryFilter(value)}
          options={mainCategories}
        /> */}
         <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Role"
          onChange={(value)=>setRoleFilter(value)}
          options={roleOption}
        />
         {/* <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Types"
          // defaultValue={['all']}
          onChange={(value)=>setCauseFilter(value)}
          options={causesOptions}
        /> */}
         <RangePicker
          style={{
            width: "100%",
          }}
          disabledDate={disabledDate}
          onChange={handleRangePicker}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#80a6ff" />
        </AreaChart>
      </ResponsiveContainer>{" "}
    </div>
  );
}

export default CharitableDonation;
