// export const baseURL = "https://api-ifers.invo.zone/api";
export const baseURL = process.env.REACT_APP_SERVER_URL; 
//'http://localhost:7000/api'; //local
// export const baseURL = "http://localhost:7000/api"; //local
export const imageURL =process.env.REACT_APP_BACKEND_IMAGE_URL;
export const marketPlaceAddress=process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDR

//LIVE VALUES OF GBP TO ETH
export const gbpToEth =process.env.REACT_APP_GPT_TO_ETH
export const getListings = baseURL + "/listings";
export const getTransactions = baseURL + "/transactions";
export const getShippings = baseURL + "/shipments";
export const getUsers = baseURL + "/users";
export const getHomeData = baseURL + "/home";
export const signIn = baseURL + "/auth/signin";
export const updateServiceFeeApi = baseURL + "/updateServiceFee";
// export const getAdminInfo = baseURL + '/getAdminInfo';
export const getAccountInfo = baseURL + "/auth/account-information";
export const updateUserProfile = baseURL + "/auth/update-profile";
// export const updateAdminInfo = baseURL + '/updateAdminInfo';
export const updatePassword = baseURL + "/auth/change-password";
export const forgetPasswordRequest = baseURL + "/auth/forget-password";
export const resetPasswordRequest = baseURL + "/auth/reset-password";

//check content api
export const getImgContent = baseURL + "/get-image-checks";
export const getTextContent = baseURL + "/get-text-checks";
export const updateImgContent = baseURL + "/update-image-checks";
export const updateTextContent = baseURL + "/update-text-checks";

//shipping APIs
export const getShippingList = baseURL + "/shipping/get-list";
export const updateShipping = baseURL + "/shipping/update";

//report
// export const reportsList = baseURL + '/report/read';

//rewards
export const rewardControls = baseURL + "/rewards/controls";
export const rewardControlsDetails = baseURL + "/rewards/controls-details";
export const rewardsList = baseURL + "/rewards/list";

// Roles module
export const rolesListing = baseURL + "/roles";
export const createRole = baseURL + "/roles/save";
export const updateRole = baseURL + "/roles/update";
export const destroyRole = baseURL + "/roles/delete";

// Permissions module
export const permissionListing = baseURL + "/permissions";
export const createPermission = baseURL + "/permissions/save";
export const updatePermission = baseURL + "/permissions/update";
export const destroyPermission = baseURL + "/permissions/delete";
export const twoFactorSetup = baseURL + "/auth/generate-2fa";
export const twoFactorVerification = baseURL + "/auth/verify-2fa";
export const usersList = baseURL + "/auth/users";

// Reports module
export const reportsListing = baseURL + "/reports";
export const reportOrganization = baseURL + "/reports/organization";
export const updateOrgEinNumber = baseURL + "/reports/update-ein";
export const banOrganization = baseURL + "/reports/ban";
export const recoverOrganization = baseURL + "/reports/recover";
// ISO letters module
export const isoLettersListing = baseURL + "/iso/iso-letters";
export const verifyIsoLettersListing =
  baseURL + "/iso/verify/iso-determination-letter";

// Causes module
export const causesListing = baseURL + "/categories";
export const createCause = baseURL + "/categories/create";
export const deleteCause = baseURL + "/categories/delete";

// Platform Settings module
export const platformSettingsListing = baseURL + "/platform-settings";
export const createOrUpdatePlatformSettings =
  baseURL + "/platform-settings/save";
// export const deleteCause = baseURL + '/categories/delete';

//Analytics
export const analyticsNFTStatsByType = baseURL + "/stats/typeOfNfts";
export const donations = baseURL + "/stats/donationsForAdmin";

export const REACT_APP_POCKET_KEY = "";
export const REACT_APP_INFURA_KEY = "";
export const REACT_APP_WALLETCONNECT_PROJECT_ID = "";
