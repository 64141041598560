import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Select, DatePicker } from "antd";
import styles from "./NFTSold.module.sass";
import axios from "axios";
import { analyticsNFTStatsByType } from "../../../environment";
import moment from "moment";
import { causesListing } from "../../../environment";
const { RangePicker } = DatePicker;

function NFtSold() {
  const [dateFilter, setDateFilter] = useState("all");
  const [data, setData] = useState(null);
  const [orignalData, setOrignalData] = useState(null);
  const [causesOptions, setCausesOptions] = useState(null);
  const [causeFilter, setCauseFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const options = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 24 hours", value: "last24Hours" },

    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const mainCategories = [
    {
      label: "Digital Art",
      value: "digital art",
    },
    {
      label: "Photography",
      value: "photography",
    },
    {
      label: "Text-based",
      value: "text-based",
    },
    {
      label: "Original & Unique Artwork (1/1)",
      value: "original-unique-artwork",
    },
    {
      label: "Event Ticket",
      value: "event-ticket",
    },
    {
      label: "Membership ID",
      value: "membership-id",
    },
    {
      label: "Video clips",
      value: "video-clips",
    },
    {
      label: "Music",
      value: "music",
    },
    {
      label: "AI-Generative",
      value: "ai-generative",
    },
    {
      label: "Avatars",
      value: "avatars",
    },
    {
      label: "Video clips & GIFs",
      value: "video-clips-gifs",
    },
  ];
  for (let i = 4; i < 4; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const getStats = async () => {
    const {startDate,endDate}=dateRange
    const stats = await axios.get(analyticsNFTStatsByType, {
      params: {
        dateFilter,
        graphType: 1,
        ...(causeFilter && { causeFilter }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setOrignalData(stats.data.data);
    formatData(stats.data.data);
  };
  useEffect(() => {
    getStats();
  }, [dateFilter, causeFilter,dateRange]);
  useEffect(() => {
    if (categoryFilter) {
      const filteredData = orignalData?.filter(
        (item) => item?.nft[0]?.secondaryType === categoryFilter
      );
      formatData(filteredData);
    } else {
      getStats();
    }
  }, [categoryFilter]);
  const getCausesListing = async () => {
    const causesData = await axios.get(causesListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (causesData?.data) {
      formatCauses(causesData?.data?.data);
    }
  };
  const formatCauses = (data) => {
    const formatedCauses = data.map((cause) => {
      return {
        label: cause.categoryName,
        value: cause._id,
      };
    });
    formatedCauses.unshift({ label: "All", value: "all" });
    setCausesOptions(formatedCauses);
  };
  const formatData = (data) => {
    let dataToFormat = null;
    if (categoryFilter) {
      dataToFormat = data.filter(
        (item) => item?.nft[0]?.secondaryType === categoryFilter
      );
    } else {
      dataToFormat = data;
    }
    let count = 0;
    const res = data.map((item) => {
      const createdAt = moment(item.date);
      count = count + item?.count;
      return {
        name:
          dateFilter === "today"
            ? createdAt.format("HH:mm")
            : createdAt.format("D MMMM"),
        No_of_NFTs_Sold: count,
        pv: count,
      };
    });
    setData(res);
  };
  useEffect(() => {
    getCausesListing();
  }, []);
  const handleChange = (value) => {
    setDateFilter(value);
  };
  const handleRangePicker = (dates) => {
    setDateFilter("all");
    setDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };
  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }

  return (
    <div className={styles.analyticscard}>
      <h3>Number Value and Type of NFTs sold</h3>
      <div className={styles.selects}>
        <Select
          //   mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Particular date"
          defaultValue={["Today"]}
          onChange={handleChange}
          options={options}
               disabled={dateRange?.startDate}
          value={dateFilter}
        />
        {/* <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Types"
          // defaultValue={['all']}
          onChange={(value) => setCauseFilter(value)}
          options={causesOptions}
       
        /> */}
        {/* <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Select by Categories"
          // defaultValue={['all']}
          onChange={(value) => setCategoryFilter(value)}
          options={mainCategories}
         
        /> */}
        <RangePicker
          style={{
            width: "100%",
          }}
          disabledDate={disabledDate}
          onChange={handleRangePicker}
     
        />
      </div>
      {data && (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            {/* <Area type="monotone" dataKey="typeOfNfts" fill="#80a6ff" stroke="#8884d8" /> */}
            {/* <Bar dataKey="pv" barSize={20} fill="#3772FF" /> */}
            <Line type="monotone" dataKey="No_of_NFTs_Sold" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default NFtSold;
